.user {
  &__main_data {
    display: flex;

    &_avatar {
      height: 220px;
      margin-top: auto;
      margin-bottom: auto;
      flex: 0 0 220px;
      object-fit: scale-down;
    }

    &__personal {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 40px;

      &__reset {
        height: 20px;
        width: 20px;
        margin-left: 15px;
      }

      p {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d2d2d2;

        input {
          padding-top: 4px;
          padding-bottom: 4px;
        }

        span {
          color: #2a2a2a;
          display: inline-block;
          width: 250px;
        }

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    column-gap: 50px;

    &__select {
      width: 100% !important;
    }

    fieldset {
      flex: 0 1 50%;

      legend {
        margin-bottom: 10px;
        font-size: 16px;
        color: #2a2a2a;
      }
    }
  ;

    button {
      margin-top: auto;
      flex: 0 0 300px;
    }
  }

  &__quiz {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;

    h2 {
      background: #2a2a2a;
      width: 100%;
      padding: 15px 15px;
      text-align: center;
      color: white;
    }

    &__block {
      border-left: 3px solid #2a2a2a;
      border-bottom: 50px solid #2a2a2a;
      border-right: 3px solid #2a2a2a;
      padding: 20px 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      &__dropdown, &__multi_input {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      &__dropdown {
        &__menu {
          width: 100% !important;
        }
      }

      &__multi_input {
        &__wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;

          input {
            width: 100%;
          }
        }
      }
    }
  }
}

span.user__main_data_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #403D39;
  color: #0296ff;
  text-transform: uppercase;
  font-size: 100px;
  text-align: center;
}
