.option {
  &:hover {
    background: white;
  }

  &:hover td {
    cursor: pointer;
    color: #2a2a2a;
  }

  td, th {
    text-align: center;
  }

  &:hover {
    th {
      background: #2a2a2a;
    }
  }

  th {
    padding: 5px 10px;
    color: white;

    &:hover {
      color: white;
    }
  }

  th:first-child {
    padding-left: 10px;
  }

  th:first-child, td:first-child {
    text-align: left !important;
  }

  input {
    width: 100px;
    text-align: center;
  }

  .reset_button {
    height: 30px;

    img {
      object-fit: scale-down;
    }
  }
}
