.button {
  background: #0296ff;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: bold;

  &:disabled {
    cursor: not-allowed;
    opacity: .4;

    &:hover {
      background: #0296ff;
    }
  }

  &:hover {
    background: #bb3200;
  }

  &_grey {
    background: #403D39;

    &:hover {
      background: #252422;
    }
  }
}
