.pagination {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;

  span {
    cursor: pointer;
    transition: .3s all;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: #2a2a2a;
    border: 2px solid #2a2a2a;
    color: white;
  }

  &_active {
    background: #0296ff !important;
    color: #FFFCF2 !important;
    border: 2px solid #2a2a2a !important;
  }
}