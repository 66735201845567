.create {
  fieldset {
    width: 100%;
    margin-bottom: 20px;

    textarea {
      height: 120px;
    }

    input, textarea {
      width: 100%;
    }

    label[for='isPrimary'] {
      display: inline;
      margin-right: 15px;
    }

    input[type='checkbox'] {
      width: auto;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }
  }

  &__basic {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
    height: 250px;

    &__thumbnail {
      flex: 0 1 35%;
      display: block;
    }

    &__text {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      fieldset {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
  }

  &__workouts {
    display: flex;
    margin-bottom: 20px;
    column-gap: 20px;

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    &__wrapper {
      flex: 1 1 50%;
      display: flex;
      row-gap: 20px;
      flex-direction: column;

      &__tile {
        flex: 0 0 50px;
        cursor: pointer;
        border: 2px solid #252422;
        background: #252422;
        display: flex;
        column-gap: 20px;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;

        &__day_off {
          img {
            padding: 5px;
            filter: invert(1);
          }
        }

        small {
          text-align: left;
          color: #FFFCF2;
          display: block;
          flex: 1 1 auto;
        }

        img {
          height: 50px;
          object-fit: cover;
          width: 50px;
        }
      }

      &__chosen_title {
        flex: 0 0 100px !important;
        padding-left: 10px;
      }

      &__pagination {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
