.search {
  display: flex;
  column-gap: 30px;

  &__input {
    flex: 1 1 auto;

    &[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  }
}
