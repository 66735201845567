.multiselect {
  position: relative;
  width: 300px;
  z-index: 0;
  padding: 5px 30px 5px 40px;
  border-bottom: 1px solid #0296ff;
  border-top: none;
  border-right: none;
  border-left: none;
  color: #252422;
  min-height: 30px;

  &_icon {
    left: 10px;
    width: 20px;
  }

  &_clear {
    right: 10px;
    width: 20px;
    cursor: pointer;
  }

  &_placeholder {
    left: 40px;
    opacity: .7;
    pointer-events: none;

  }

  &_placeholder, &_clear, &_icon {
    top: 50%;
    transform: translate(0, -50%);
  }

  &_placeholder, &__menu, &_icon, &_clear {
    position: absolute;
  }

  &__menu, &__selected {
    background: white;
    width: 100%;
  }

  &__selected {
    min-height: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
      outline: none;
      border: none;
      background: transparent;
    }
  }

  &__menu {
    left: 50%;
    transform: translate(-50%, 0);
    width: 99%;
    top: 110%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    list-style-type: none;
    max-height: 200px;
    overflow: auto;

    &_option {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background: #252422;
        color: #FFFCF2;
      }
    }
  }
}
