.equipment {
  &__table {
    tbody {
      tr:hover {
        cursor: default;
        background: none;

        td {
          color: #252422
        }
      }
    }
  }
}