.wrapper {
  height: 100% !important;
  width: 100% !important;
  border: 1px solid #9b9b9b;
  border-radius: 15px;
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  input {
    width: 0;
  }
}
