.wrapper {
  position: absolute;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 48px;
    animation: logoLoaderAnim 1s infinite ease-in-out;
    color: #0296ff;
    font-family: "SFProDisplay-Bold", sans-serif;
    background-color: red;
    background-image: linear-gradient(93.21deg, #0497fe 0.92%, #a2d8ff 98.24%);
    background-size: 200% 200%;
    background-repeat: repeat;
    line-height: normal;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

@keyframes logoLoaderAnim {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}
